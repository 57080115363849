import { Navigate, Outlet } from "react-router-dom";
import StorageBox from "../../core/storage";
import { IWorkspace } from "../interface";
import { useEffect } from "react";

export default function PrivateRoute() {
  const companyDocs: IWorkspace | null = StorageBox.getCompanyDocs();
  const user = StorageBox.getUser();
  const token = StorageBox.getToken();

  const first_email_verified: number | undefined =
    companyDocs?.company_email_confirmations?.[0]?.first_email_verified;
  const second_email_verified: number | undefined =
    companyDocs?.company_email_confirmations?.[0]?.second_email_verified;

  const today = new Date();
  const expiredDate = companyDocs?.subscription?.expired;

  const subStatus =
    !expiredDate || new Date(expiredDate).getTime() > new Date().getTime();
  const planName = companyDocs?.subscription?.subscription_plan?.name || "";

  if (token !== null && user !== null && companyDocs !== null) {
    if (
      (first_email_verified === 0 || second_email_verified === 0) &&
      planName?.toLocaleUpperCase() !== "FREE"
    ) {
      return <Navigate to="/upload-company-documents" />;
    } else if (planName?.toLocaleUpperCase() !== "FREE" && !subStatus) {
      return <Navigate to="/upload-billing-account" />;
    } else {
      return <Outlet />;
    }
  } else if (token !== null && user !== null) {
    return <Navigate to="/upload-company-documents" />;
  } else {
    return <Navigate to={`/`} />;
  }
}
