import React, { useEffect } from "react";
// import { Route, MemoryRouter as Router, Routes } from "react-router-dom";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import PageLoading from "./components/spinners/PageLoading";
import { ToastContainer } from "react-toastify";
import PrivateRoute from "./components/hoc/PrivateRoute";
import OutletWithProvider from "./context/OutletWithProvider";
import { Spin } from "antd";
import { QueryClient, QueryClientProvider } from "react-query";

const TermsOfService = React.lazy(() => import("./pages/auth/TermsOfService"));
const PrivacyPolicy = React.lazy(() => import("./pages/auth/PrivacyPolicy"));
const PageNotFound = React.lazy(() => import("./pages/auth/PageNotFound"));
const Packages = React.lazy(() => import("./pages/Extras/Packages"));
const Login = React.lazy(() => import("./pages/auth/Login"));
const Signup = React.lazy(() => import("./pages/auth/Signup"));
const VerifyOTPRegistration = React.lazy(
  () => import("./pages/auth/VerifyOTPRegistration")
);
const VerifyEmailOne = React.lazy(() => import("./pages/auth/VerifyEmailOne"));
const VerifyEmailTwo = React.lazy(() => import("./pages/auth/VerifyEmailTwo"));
const BillingAccount = React.lazy(() => import("./pages/auth/BillingAccount"));
const Wrapper = React.lazy(() => import("./pages/auth/Wrapper"));
const ForgotPassword = React.lazy(() => import("./pages/auth/ForgotPassword"));
const Dashboard = React.lazy(() => import("./pages/dashboard/Dashboard"));
const Extensions = React.lazy(() => import("./pages/Extensions/Extensions"));
const VoiceHistory = React.lazy(
  () => import("./pages/CallHistory/VoiceHistory")
);
const VideoHistory = React.lazy(
  () => import("./pages/CallHistory/VideoHistory")
);
const Organization = React.lazy(
  () => import("./pages/Organization/Organization")
);
const NoticeBoard = React.lazy(() => import("./pages/Notice/NoticeBoard"));
const UploadCompanyInformation = React.lazy(
  () => import("./pages/auth/CompanyInformation")
);
// const Reporting = React.lazy(() => import("./pages/Reporting/Reporting"));
const Advertisements = React.lazy(
  () => import("./pages/Advertisement/Advertisements")
);
const Billing = React.lazy(() => import("./pages/Billing/Billing"));
const Storage = React.lazy(() => import("./pages/storage/Storage"));
const VerifyPayment = React.lazy(() => import("./pages/Billing/VerifyPayment"));
const UpdateCompany = React.lazy(
  () => import("./pages/update-company/UpdateCompany")
);

function App() {
  const googleTranslateElementInit = () => {
    new window.google.translate.TranslateElement(
      {
        pageLanguage: "en",
        autoDisplay: false,
      },
      "google_translate_element"
    );
  };
  // useEffect(() => {
  //   var addScript = document.createElement("script");
  //   addScript.setAttribute(
  //     "src",
  //     "//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit"
  //   );
  //   document.body.appendChild(addScript);
  //   window.googleTranslateElementInit = googleTranslateElementInit;
  // }, []);

  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <React.Suspense fallback={<PageLoading />}>
        <div className="flex justify-end">
          <div id="google_translate_element"></div>
        </div>
        <Router>
          <Routes>
            <Route path="/*" element={<PageNotFound />} />
            <Route path="/" element={<Wrapper />} />
            <Route path="/signin" element={<Login />} />
            <Route path="/signup" element={<Signup />} />
            <Route
              path="/otp-verification"
              element={<VerifyOTPRegistration />}
            />
            <Route path="/forgot-password" element={<ForgotPassword />} />
            <Route
              path="/upload-company-documents"
              element={<UploadCompanyInformation />}
            />
            <Route path="/update-company" element={<UpdateCompany />} />
            <Route path="/verify-email-one" element={<VerifyEmailOne />} />
            <Route path="/verify-email-two" element={<VerifyEmailTwo />} />
            <Route
              path="/upload-billing-account"
              element={<BillingAccount />}
            />
            <Route path="/packages" element={<Packages />} />
            <Route path="/dodo-terms-of-service" element={<TermsOfService />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />

            {/* Private routes  */}
            <Route element={<PrivateRoute />}>
              <Route element={<OutletWithProvider />}>
                <Route path="/dashboard" element={<Dashboard />} />
                <Route path="/users" element={<Extensions />} />
                <Route path="/voice-history" element={<VoiceHistory />} />
                <Route path="/video-history" element={<VideoHistory />} />
                <Route path="/organization" element={<Organization />} />
                <Route path="/notice-board" element={<NoticeBoard />} />
                {/* <Route path="/reporting" element={<Reporting />} /> */}
                <Route path="/advertisement" element={<Advertisements />} />
                <Route path="/billing" element={<Billing />} />
                <Route path="/storage" element={<Storage />} />
                <Route path="/payment-complete" element={<VerifyPayment />} />
              </Route>
            </Route>
          </Routes>
        </Router>
        <ToastContainer />
      </React.Suspense>
    </QueryClientProvider>
  );
}

export default App;
