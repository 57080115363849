import { useState } from "react";
import Context from "./context";
import { IContextGetData } from "../components/interface";

export default function Provider({ children }: { children: React.ReactNode }) {
  const [sidebarVisible, setSidebarVisible] = useState<boolean>(false);

  const [dashboardState, setDashboardState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [extensionState, setExtensionState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [callHistoryState, setCallHistoryState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [videoHistoryState, setVideoHistoryState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [organizationState, setOrganizationState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });

  const [adsState, setAdsState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });

  const [noticeBoardState, setNoticeBoardState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });
  const [billingState, setBillingState] = useState<IContextGetData>({
    page: 1,
    size: 20,
    loading: true,
    data: null,
  });

  return (
    <Context.Provider
      value={{
        sidebarVisible,
        setSidebarVisible,

        /**Dashboard */
        dashboardState,
        setDashboardState,
        extensionState,
        setExtensionState,

        /**Call History */
        callHistoryState,
        setCallHistoryState,
        videoHistoryState,
        setVideoHistoryState,

        /**Organization */
        organizationState,
        setOrganizationState,

        /**Advertisement */
        adsState,
        setAdsState,

        // Notice Board
        noticeBoardState,
        setNoticeBoardState,

        billingState,
        setBillingState,
      }}
    >
      {children}
    </Context.Provider>
  );
}
